<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-user"></i><strong> Utilisateurs</strong>
              <div class="card-header-actions">
                <b-button variant="success" @click="onExport" title="Exporter en PDF" class="mr-2" v-b-tooltip.hover>
                  <i class="fa fa-upload"></i>
                </b-button>

                <b-button variant="primary" @click="addUserModal = true">
                  <i class="fa fa-plus"></i>
                  Ajouter un utilisateur
                </b-button>

                <b-modal title="Nouvel utilisateur" class="modal-primary" v-model="addUserModal"
                         @show="onModalShow" @hide="onModalClose">
                  <description-message>
                    Pour ajouter un utilisateur à votre exploitation agricole, vous devez fournir les informations suivantes.
                  </description-message>
                  <div class="text-center" v-if="fetchingSecretQuestion">
                    <b-spinner></b-spinner>
                    <br>
                    Chargement...
                  </div>
                  <b-form v-else-if="secretQuestion">
                    <p class="text-danger" v-if="hasError">{{errorMessage}}</p>

                    <c-input container-class="mb-3 mt-3" label="Code client de l'utilisateur" placeholder="Ex: 123456"
                             v-model="code" :state="codeState">
                      Veuillez saisir un code
                      <template slot="infoMessage">
                        C'est le code Client de l'utilisateur que vous souhaitez ajouter à votre exploitation. Cet utilisateur vous
                        fournira son code pour que l'ajout soit effectué.
                      </template>
                    </c-input>

                    <c-input container-class="mb-3 mt-3" :label="secretQuestion.name" placeholder="Entrez la réponse..."
                             v-model="answer" :state="answerState">
                      Veuillez entrer une réponse
                      <template slot="infoMessage">
                        vous devez fournir la reponse à une de vos questions secrètes pour effectuer l'ajout.
                      </template>
                    </c-input>
                  </b-form>
                  <p class="text-center text-danger" v-else>Echec de la connexion au serveur</p>

                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="addUser"
                                    :fetching="fetchingAddUser">
                      Ajouter
                    </button-spinner>
                    <b-button variant="secondary" @click="addUserModal = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>

                <b-modal title="Gestion des droits" class="modal-primary" v-model="manageRightsModal"
                         @show="onModalShow" @hide="onModalClose">

                  <div class="text-center" v-if="fetchingSecretQuestion">
                    <b-spinner></b-spinner>
                    <br>
                    Chargement...
                  </div>
                  <b-form v-else-if="secretQuestion">
                    <p class="ml-2"> <i class="fa fa-user"></i> {{selectedUser.name}}</p>

                    <b-input-group class="mb-4 ml-4" v-for="right in rights">
                      <b-form-checkbox class="text-muted cursor-pointer" v-model="checkedRights" :value="right.id">
                        {{right.name}}
                      </b-form-checkbox>
                    </b-input-group>

                    <c-input container-class="mb-3 mt-3" :label="secretQuestion.name" placeholder="Entrez la réponse..."
                             v-model="answer" :state="answerState">
                      Veuillez entrer une réponse
                    </c-input>
                  </b-form>
                  <p class="text-center text-danger" v-else>Echec de la connexion au serveur</p>

                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="updateUserRights"
                                    :fetching="fetchingUpdateRights">
                      Enregistrer
                    </button-spinner>
                    <b-button variant="secondary" @click="manageRightsModal = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
              </div>
            </div>
            <div class="text-center" v-if="fetchingUserList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-row v-else-if="users.length" class="exploitations">
              <c-table :table-data="users" :fields="fields" :actions="actions" :per-page=5 striped outlined
                       fixed></c-table>
            </b-row>
            <p class="text-center" v-else>Aucun utilisateur ne travaille dans cette exploitation.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Regex, Toast, asset, IO} from "../../../helpers"

  export default {
    name: "ExploitationUsers",
    title: "PIA - Utilisateurs",
    data() {
      return {
        users: [],
        fields: [
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'registeredAt', label: 'Enregistré le', sortable: true},
          {key: 'rightsStr', label: 'Droits'},
          {key: 'status', label: 'Statut', sortable: true},
          {key: 'actions', label: 'Actions'},
        ],
        actions: [
          {type: 'primary', icon: 'icon-note', title: 'Modifier les droits', handler: this.editUser},
          {type: 'danger', icon: 'icon-ban', title: 'Retirer', handler: this.deleteUser},
        ],
        addUserModal: false,
        manageRightsModal: false,
        code: '',
        submitted: false,
        error: null,
        fetchingAddUser: false,
        fetchingUserList: false,
        fetchingUpdateRights: false,
        fetchingRightList: false,
        rights: [],
        checkedRights: [],
        selectedUser: {},
        fetchingSecretQuestion: false,
        secretQuestion: null,
        answer: ''
      }
    },
    created() {
      this.fetchingUserList = true
      Api.get('/exploitation/user/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.users = res.data.data.map(user => ({
              ...user,
              rightsStr: user.rights.map(right => right.name).join(', '),
              status: user.confirmed ? 'Confirmé' : 'Non confirmé',
              statusVariant: user.confirmed ? 'success' : 'warning'
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingUserList = false
        })

      this.fetchingRightList = true
      Api.get('/right/list')
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.rights = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingRightList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      codeState() {
        return !this.submitted || Regex.isValidUserCode(this.code) ? null : false
      },
      answerState() {
        return !this.submitted || (this.answer && this.answer.length) ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    methods: {
      asset: asset,
      valid() {
        return Regex.isValidUserCode(this.code)
      },
      onModalShow() {
        this.fetchingSecretQuestion = true
        Api.get('/user/secret-questions/random')
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.secretQuestion = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingSecretQuestion = false
          })
      },
      onModalClose() {
        this.code = ''
        this.submitted = false
        this.error = null
        this.fetchingAddUser = false
        this.answer = ''
      },
      addUser() {
        this.submitted = true
        if (!this.valid()) return

        this.fetchingAddUser = true
        Api.post('/exploitation/user/add', {
          exploitationId: this.exploitationId,
          userCode: parseInt(this.code),
          answer: this.answer
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.addUserModal = false
              this.users.push({
                ...res.data.data,
                rightsStr: '',
                status: res.data.data.confirmed ? 'Confirmé' : 'Non confirmé',
                statusVariant: res.data.data.confirmed ? 'success' : 'warning'
              })
              Toast.success("Email de confirmation envoyé à l'utilisateur invité ! Vous pourrez continuer dès sa confirmation.")
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingAddUser = false
          })
      },
      updateUserRights() {
        this.fetchingUpdateRights = true
        Api.post('/exploitation/user/grant', {
          exploitationId: this.exploitationId,
          userId: this.selectedUser.id,
          rights: this.checkedRights,
          answer: this.answer
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.manageRightsModal = false
              this.users = this.users.filter((item, index, array) => item.id !== this.selectedUser.id)
              this.users.push({
                ...res.data.data,
                rightsStr: res.data.data.rights.map(right => right.name).join(', '),
                status: res.data.data.confirmed ? 'Confirmé' : 'Non confirmé',
                statusVariant: res.data.data.confirmed ? 'success' : 'warning'
              })
              Toast.success('Les droits de l\'utilisateur ont été mis à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingUpdateRights = false
          })
      },
      editUser(user) {
        this.selectedUser = user
        this.manageRightsModal = true
        this.checkedRights = []
        user.rights.forEach(right => this.checkedRights.push(right.id))
      },
      deleteUser(user) {
        const res = confirm(`Etes-vous sûr de vouloir retirer l'utilisateur "${user.name}" ?`)
        if (res) {
          Api.post('/exploitation/user/remove', {
            exploitationId: this.exploitationId,
            userId: user.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.users = this.users.filter((item, index, array) => item.id !== user.id)
                Toast.success('Utilisateur retiré avec succès !')
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              this.fetchingAddUser = false
            })
        }
      },
      onExport() {
        IO.exportDataTable(this.users, 'users', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des utilisateurs',
          headers: [
            {title: 'Nom', key: 'name', width: 'auto'},
            {title: 'Enregistré le', key: 'registeredAt', width: 'auto'},
            {title: 'Droits', key: 'rightsStr', width: '*'},
            {title: 'Statut', key: 'status', width: 'auto'},
          ]
        })
      }
    }
  }
</script>

<style scoped>
  .secret-question-hint {
    font-size: 16px;
  }
</style>
